<template>
  <div class="container-fluid pb-5">
    <b-card v-if="!editingPlan" class="af-card">
      <div class="bg-primary curved-edge">
        <b-row class="align-items-end justify-content-between text-white py-4 px-3">
          <b-col cols="9">
            <p class="text-uppercase">Edit Network Plan</p>
            <p class="text-uppercase">Set data rates on each network</p>
          </b-col>
        </b-row>
      </div>
      <div class="my-4">
        <b-row v-for="item in networks" :key="item._id">
          <b-col cols="9">
            <p class="text-uppercase">{{ item.networkName }}</p>
          </b-col>
          <b-col cols="3" class="text-right">
            <b-button @click="editPlan(item)" variant="empty">Edit</b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card v-else class="af-card">
      <EditDataPlan :selectedNetwork="selectedNetwork" :planId="planId" @cancel-edit="editingPlan = false" />
    </b-card>
  </div>
</template>

<script>
import { getNetworks } from '../apis/network'
import EditDataPlan from '../components/network/EditDataPlan.vue'

export default {
  metaInfo: {
    title: 'Network',
    titleTemplate: '%s | AirtimeFlip'
  },
  components: {
    EditDataPlan
  },
  data () {
    return {
      editingPlan: false,
      networks: [],
      planId: '',
      selectedNetwork: ''
    }
  },
  async created () {
    try {
      const { data } = await getNetworks()
      this.networks = data.data
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    editPlan (item) {
      this.planId = item._id
      this.editingPlan = true
      this.selectedNetwork = item.networkName
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
