<template>
  <div class="px-4">
    <div class="row no-gutters items-center mb-4">
      <div class="col-6">
        <h3>Network Plans</h3>
      </div>
      <div class="col-6 text-right">
        <b-button variant="primary" @click="addModalOpen = true">Add New Plan</b-button>
        <b-button variant="empty" @click="$emit('cancel-edit')">Cancel</b-button>
      </div>
    </div>

    <b-table
      id="af-table"
      :items="plans"
      :fields="fields"
      hover
      responsive
      borderless
      show-empty
    >
      <template #empty>
        <p class="text-center text-muted">No plans found</p>
      </template>

      <template v-slot:head()="data">
        <span class="text-uppercase font-weight-bold title">{{ data.label }}</span>
      </template>

      <template v-slot:head(title)>
        <span class="text-uppercase font-weight-bold title">Plan</span>
      </template>

      <template v-slot:head(data_id)>
        <span class="text-uppercase font-weight-bold title">Action</span>
      </template>

      <template v-slot:cell(data_id)="data">
        <span @click="openEditPlan(data.item)" class="icon-pencil edit mr-3"></span>
        <span @click="openDeletePlan(data.item)" class="del">x</span>
      </template>
    </b-table>

    <!-- add plan modal -->
    <b-modal v-model="addModalOpen" content-class="af-modal" hide-header hide-footer centered>
      <p class="text-uppercase font-weight-bold mb-4">Add Network</p>
      <form @submit.prevent="addPlan">
        <b-row>
          <b-col cols="6">
            <p class="text-14 text-uppercase">Network</p>
            <p class="text-uppercase">{{ selectedNetwork }}</p>
          </b-col>
          <b-col cols="6">
            <p class="text-14 text-uppercase">Plan Name</p>
            <b-form-input v-model="planName" class="af-input-field mb-4" required></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <p class="text-14 text-uppercase">Data code</p>
            <b-form-input v-model="dataCode" class="af-input-field mb-4" required></b-form-input>
          </b-col>
          <b-col cols="6">
            <p class="text-14 text-uppercase">Price</p>
            <b-form-input v-model="planAmount" class="af-input-field mb-4" type="number" required></b-form-input>
          </b-col>
          <b-col cols="6">
            <p class="text-14 text-uppercase">Short Code</p>
            <b-form-input v-model="shortcode" class="af-input-field mb-4" type="number" required></b-form-input>
          </b-col>
          <b-col cols="6">
            <p class="text-14 text-uppercase">Short Code SMS</p>
            <b-form-input v-model="shortcode_sms" class="af-input-field mb-4" type="text" required></b-form-input>
          </b-col>
          <b-col cols="12">
            <p class="text-14 text-uppercase">Steps (optional)</p>
            <b-form-input v-model="planSteps" class="af-input-field mb-4" type="text"></b-form-input>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button :disabled="addingPlan" variant="primary" type="submit" class="px-4">
            <b-spinner v-if="addingPlan" label="Spinning" small></b-spinner>
            <span v-else>Add Plan</span>
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- add plan modal ends -->

    <!-- edit plan modal -->
    <b-modal v-model="editModalOpen" content-class="af-modal" hide-header hide-footer centered>
      <p class="text-uppercase font-weight-bold mb-4">Edit Network</p>
      <form @submit.prevent="editPlan">
        <b-row>
          <b-col cols="6">
            <p class="text-14 text-uppercase">Network</p>
            <p class="text-uppercase">{{ selectedNetwork }}</p>
          </b-col>
          <b-col cols="6">
            <p class="text-14 text-uppercase">Network Plan</p>
            <b-form-input v-model="selectedPlan" class="af-input-field mb-4" required></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <p class="text-14 text-uppercase">Data code</p>
            <b-form-input v-model="selectedPlanCode" class="af-input-field mb-4" required></b-form-input>
          </b-col>
          <b-col cols="6">
            <p class="text-14 text-uppercase">Price</p>
            <b-form-input v-model="selectedAmount" class="af-input-field mb-4" type="number" required></b-form-input>
          </b-col>
          <b-col cols="6">
            <p class="text-14 text-uppercase">Short Code</p>
            <b-form-input v-model="editShortcode" class="af-input-field mb-4" type="number" required></b-form-input>
          </b-col>
          <b-col cols="6">
            <p class="text-14 text-uppercase">Short Code SMS</p>
            <b-form-input v-model="editShortcode_sms" class="af-input-field mb-4" type="text" required></b-form-input>
          </b-col>
          <b-col cols="12">
            <p class="text-14 text-uppercase">Steps (optional)</p>
            <b-form-input v-model="selectedPlanSteps" class="af-input-field mb-4" type="text"></b-form-input>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button :disabled="updatingPlan" variant="primary" type="submit" class="px-4">
            <b-spinner v-if="updatingPlan" label="Spinning" small></b-spinner>
            <span v-else>Save</span>
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- edit plan modal ends -->

    <!-- delete plan modal -->
    <b-modal v-model="deleteModalOpen" content-class="af-modal" hide-header hide-footer centered>
      <p class="text-uppercase font-weight-bold text-center mb-4">Delete {{ selectedPlan }} Plan</p>
      <p class="text-center">Are you sure you want to delete this plan?</p>
      <div class="text-right">
        <b-button @click="deletePlan" :disabled="deletingPlan" variant="danger" class="mt-4 px-4">
          <b-spinner v-if="deletingPlan" label="Spinning" small></b-spinner>
          <span v-else>Delete</span>
        </b-button>
        <b-button @click="deleteModalOpen = false" :disabled="deletingPlan" variant="empty" class="mt-4 px-4">
          <span>Cancel</span>
        </b-button>
      </div>
    </b-modal>
    <!-- delete plan modal -->
  </div>
</template>

<script>
import { getOnePlan, updatePlan, deletePlan, addPlan } from '../../apis/network'

export default {
  props: {
    planId: {
      type: String,
      default: ''
    },
    selectedNetwork: String
  },
  data () {
    return {
      plans: [],
      fields: [
        'title',
        'amount',
        'code',
        'shortcode',
        'shortcode_sms',
        'multisteps',
        'data_id'
      ],
      dataCode: '',
      selectedPlan: '',
      selectedAmount: '',
      selectedNetworkId: '',
      selectedOldId: '',
      selectedPlanCode: '',
      selectedPlanSteps: '',
      planName: '',
      planAmount: '',
      shortcode: '',
      shortcode_sms: '',
      editShortcode: '',
      editShortcode_sms: '',
      planSteps: '',
      addModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      updatingPlan: false,
      deletingPlan: false,
      addingPlan: false
    }
  },
  created () {
    this.getPlans()
  },
  methods: {
    openEditPlan (plan) {
      this.selectedPlan = plan.title
      this.selectedAmount = plan.amount
      this.selectedNetworkId = plan.network
      this.selectedOldId = plan._id
      this.selectedPlanCode = plan.code
      this.editShortcode = plan.shortcode
      this.editShortcode_sms = plan.shortcode_sms
      this.selectedPlanSteps = plan.multisteps
      this.editModalOpen = true
    },
    openDeletePlan (plan) {
      this.selectedPlan = plan.title
      this.selectedPlanCode = plan._id
      this.deleteModalOpen = true
    },
    async getPlans () {
      try {
        const { data } = await getOnePlan(this.planId)
        this.plans = data.plan
        console.log(this.plans)
      } catch (error) {
        console.log(error)
      }
    },
    async addPlan () {
      const payload = {
        title: this.planName,
        amount: this.planAmount,
        shortcode: this.shortcode,
        shortcode_sms: this.shortcode_sms,
        code: this.dataCode,
        steps: this.planSteps
      }
      try {
        this.addingPlan = true
        await addPlan(payload, this.planId)
        this.addingPlan = false
        this.addModalOpen = false
        this.planName = ''
        this.planAmount = ''
        this.shortcode = ''
        this.shortcode_sms = ''
        this.dataCode = ''
        this.$bvToast.toast('Plan added!', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.getPlans()
      } catch (error) {
        this.addingPlan = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    },
    async editPlan () {
      const payload = {
        title: this.selectedPlan,
        amount: this.selectedAmount,
        code: this.selectedPlanCode,
        shortcode: this.editShortcode,
        shortcode_sms: this.editShortcode_sms,
        steps: this.selectedPlanSteps
      }
      try {
        this.updatingPlan = true
        await updatePlan(payload, this.selectedNetworkId, this.selectedOldId)
        this.updatingPlan = false
        this.editModalOpen = false
        this.$bvToast.toast('Plan updated!', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.getPlans()
      } catch (error) {
        this.updatingPlan = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    },
    async deletePlan () {
      try {
        this.deletingPlan = true
        await deletePlan(this.selectedPlanCode)
        this.deletingPlan = false
        this.deleteModalOpen = false
        this.$bvToast.toast('Plan deleted!', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.getPlans()
      } catch (error) {
        this.deletingPlan = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit {
  color: #F38D04;
  cursor: pointer;
}
.del {
  background-color: #F38D04;
  color: white;
  padding: 3px 5px;
  cursor: pointer;
}
</style>
